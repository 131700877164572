<template>
  <div class="cedantRegistrationStep1">

    <div class="row">
      <div class="col-12 pr-4">
        <h3 class="gavc-h3">Présentez-nous votre projet</h3>

        <p class="gavc-question">
          Le motif de la cession de votre entreprise permet d’adapter le montage juridique et fiscal proposé par Garance à vos côtés.
        </p>

        <p class="gavc-label">
          Vous cédez vos parts parce que
          <span class="gavc-required"> * </span>
        </p>
        <div
          class="gavc-field">
          <div class="gavc-radio-checkbox gavc-radio-checkbox--is-vert">
            <div class="gavc-radio-icon" v-for="option in cedantPersonalProjectOptions" :key="option.value">
              <input v-model="cedantPersonalProject"  type="radio" :id="`registration-step-1-${option.value}`" :name="`registration-step-1-${option.value}`" :value="option.value">
              <label :for="`registration-step-1-${option.value}`">
                  <i :class="`gavc-icon gavc-icon-${option.icon}`"></i>
                  {{option.text}}
              </label>
            </div>
          </div>
          <p v-if="$v.cedantPersonalProject.$dirty ? $v.cedantPersonalProject.$error : null " class="gavc-p gavc-text-rose">
            <i class="gavc-icon gavc-icon-cross" /> Veuillez renseigner la raison de cession de vos parts
          </p>
        </div>


        <p class="gavc-label mt-3">
          Que cédez-vous ?
          <span class="gavc-required"> * </span>
        </p>
        <div class="gavc-field">
          <div class="gavc-radio-checkbox gavc-radio-checkbox--is-vert">
            <div class="gavc-radio-icon" v-for="option in elementSoldOptions" :key="option.value">
              <input v-model="cedantOffreCessionElementSold"  type="radio" :id="`registration-step-1-${option.value}`" :name="`registration-step-1-${option.value}`" :value="option.value">
              <label :for="`registration-step-1-${option.value}`">
                  {{option.text}}
              </label>
            </div>
          </div>
          <p v-if="$v.cedantOffreCessionElementSold.$dirty ? $v.cedantOffreCessionElementSold.$error : null " class="gavc-p gavc-text-rose">
            <i class="gavc-icon gavc-icon-cross" /> Veuillez renseigner ce que vous cédez
          </p>
        </div>
        <div class="mt-2">
          <helpingSocieteFondsCommerceSidebar />
        </div>
      </div>
    </div>

    <hr>

    <h3 class="gavc-h3">Vos informations</h3>

    <div class="row mt-3">
      <div class="col-12 col-md-6">
        <div
          class="gavc-field"
          :class="{ 'is-error': $v.cedantUserFirstName.$error && $v.cedantUserFirstName.$dirty }">
          <label class="gavc-label" for="registration-step-1-cedantUserFirstName-group">
            Prénom
            <span class="gavc-required"> * </span>
          </label>
          <input
            class="gavc-input"
            id="registration-step-1-cedantUserFirstName-group"
            maxlength="128"
            @input="$v.cedantUserFirstName.$touch()"
            type="text"
            v-model="cedantUserFirstName"
          >
        </div>
        <p v-if="$v.cedantUserFirstName.$dirty ? $v.cedantUserFirstName.$error : null " class="gavc-p gavc-text-rose">
          <i class="gavc-icon gavc-icon-cross" /> Votre prénom doit comporter entre 2 et 128 caractères
        </p>
      </div>
      <div class="col-12 col-md-6">
        <div
          class="gavc-field"
          :class="{ 'is-error': $v.cedantUserLastName.$error && $v.cedantUserLastName.$dirty }">
          <label class="gavc-label" for="registration-step-1-cedantUserLastName-group">
            Nom
            <span class="gavc-required"> * </span>
          </label>
          <input
            class="gavc-input"
            id="registration-step-1-cedantUserLastName-group"
            maxlength="128"
            @input="$v.cedantUserLastName.$touch()"
            type="text"
            v-model="cedantUserLastName"
          >
        </div>
        <p v-if="$v.cedantUserLastName.$dirty ? $v.cedantUserLastName.$error : null " class="gavc-p gavc-text-rose">
          <i class="gavc-icon gavc-icon-cross" /> Votre nom doit comporter entre 2 et 128 caractères
        </p>
      </div>
    </div>

    <div
      class="gavc-field mt-2"
      v-bind:class="{ 'is-error': $v.cedantUserPhoneNumber.$error && $v.cedantUserPhoneNumber.$dirty }"
    >
      <label class="gavc-label" for="registration-step-1-cedantUserPhoneNumber-group">
        Téléphone mobile
      </label>
      <input
        class="gavc-input"
        id="registration-step-1-cedantUserPhoneNumber-group"
        maxlength="14"
        @input="$v.cedantUserPhoneNumber.$touch()"
        type="tel"
        placeholder="06 __ __ __ __"
        v-mask="'XX XX XX XX XX'"
        v-model="maskedPhoneNumber"
      >
      <inputErrorValidationMessage
        v-if="$v.cedantUserPhoneNumber.$invalid"
        message="Votre numéro de téléphone doit être composé de 10 chiffres"
      />
    </div>

    <div class="gavc-field">
      <div class="gavc-radio-checkbox">
        <label class="gavc-label" for="registration-step-1-cedantIsPersonnePhysique">
            Vous détenez l'entreprise à céder directement ou par le biais d’une société ?
            <span class="gavc-required"> * </span>
        </label>
        <div class="gavc-radio">
          <input :value="true" type="radio" id="cedantIsPersonnePhysique-input-1"
                 name="registration-step-1-cedantIsPersonnePhysique" v-model="cedantIsPersonnePhysique">
          <label class="gavc-label" for="cedantIsPersonnePhysique-input-1">
            Directement (personne physique ou société en cours de constitution)
            <i class="gavc-icon gavc-icon-check" ></i>
          </label>
          <br>
          <input :value="false" type="radio" id="cedantIsPersonnePhysique-input-2"
                 name="cedantIsPersonnePhysique-input-2" v-model="cedantIsPersonnePhysique">
          <label class="gavc-label" for="cedantIsPersonnePhysique-input-2">
            Par le biais d'une société constituée
            <i class="gavc-icon gavc-icon-check" ></i>
          </label>
        </div>
        <p v-if="$v.cedantIsPersonnePhysique.$dirty ? $v.cedantIsPersonnePhysique.$error : null " class="gavc-p gavc-text-rose">
          <i class="gavc-icon gavc-icon-cross" /> Veuillez renseigner cette information
        </p>
      </div>
    </div>
    <div v-if="cedantIsPersonnePhysique === 'false' || cedantIsPersonnePhysique === false">
      <h3 class="gavc-h3">Votre entreprise</h3>
      <div
        class="gavc-field"
        v-bind:class="{ 'is-error': $v.cedantCompanyName.$dirty ? $v.cedantCompanyName.$error : null  }">
        <label class="gavc-label" for="registration-step-1-cedantCompanyName-group">
            La raison sociale de votre entreprise est
            <span class="gavc-required"> * </span>
        </label>
        <input
          class="gavc-input"
          id="registration-step-1-cedantCompanyName-group"
          maxlength="250"
          placeholder="Raison sociale"
          @input="$v.cedantCompanyName.$touch()"
          type="text"
          v-model="cedantCompanyName"
        >
        <p v-if="$v.cedantCompanyName.$dirty ? $v.cedantCompanyName.$error : null " class="gavc-p gavc-text-rose">
          <i class="gavc-icon gavc-icon-cross" /> Le nom de votre entreprise doit comporter entre 2 et 250 caractères
        </p>
      </div>
    </div>

    <div class='row mt-4'>
      <div class="col-12">
        <span @mouseenter="$v.$touch()">
          <button
            class="float-right mt-2 gavc-btn"
            @click="nextStep"
            :disabled="isInvalidInformation">
              Suivant
          </button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { maxLength, minLength, required, requiredIf } from 'vuelidate/lib/validators'
import { createHelpers } from 'vuex-map-fields';

import renderMixins from "@/mixins/renderMixins"
import { elementSoldList } from '@/variables/elementSoldList'
import { cedantPersonalProjectList } from '@/variables/cedantPersonalProjectList'

const helpingSocieteFondsCommerceSidebar = () => import('@/components/informations/helpingSocieteFondsCommerceSidebar')


const { mapFields } = createHelpers({
  mutationType: 'updateFieldWithLocalStorage',
});


export default {
  name: 'cedantRegistrationProject',
  components: {
    helpingSocieteFondsCommerceSidebar
  },
  mixins: [
    renderMixins,
    validationMixin
  ],
  data: function () {
    return {
      cedantPersonalProjectOptions: cedantPersonalProjectList,
      elementSoldOptions: elementSoldList,

      maskedPhoneNumber: ''
    }
  },
  computed: {
    ...mapFields([
      'cedant.offreCession.cedantOffreCessionElementSold',
      "cedant.cedantIsPersonnePhysique", "cedant.cedantPersonalProject",
      'cedant.company.cedantCompanyName',
      'cedant.user.cedantUserPhoneNumber', 'cedant.user.cedantUserFirstName', 'cedant.user.cedantUserLastName',
    ]),
    isInvalidInformation () {
      if (this.$v.$invalid) {
        return true
      }
      return false
    }
  },
  mounted () {
    this.maskedPhoneNumber = this.cedantUserPhoneNumber
  },
  watch: {
    maskedPhoneNumber (value) {
      this.cedantUserPhoneNumber = value.replace(/ /g,'')
    }
  },
  validations: {
    cedantUserPhoneNumber: {
      minLength: minLength(10),
      maxLength: maxLength(10)
    },
    cedantOffreCessionElementSold: {
      required
    },
    cedantUserFirstName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(128)
    },
    cedantIsPersonnePhysique: {
      required,
    },
    cedantUserLastName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(250)
    },
    cedantCompanyName: {
      required: requiredIf(function() {
        return this.cedantIsPersonnePhysique === false
      }),
      minLength: minLength(2),
      maxLength: maxLength(250)
    },
    cedantPersonalProject: {
      required,
    },
  },
  methods: {
    nextStep () {
      this.$emit('nextStep')
    }
  }
}
</script>
