export const elementSoldList = [
  {
    "text": "Société​",
    "value": "SOCIETE"
  },
  {
    "text": "Fonds de commerce",
    "value": "FDC"
  }
]
